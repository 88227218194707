<template>
  <div class="project-list-section">
    <div
      class="project-details-main container-fluid"
      :style="{ height: '100%' + w_height + '%' }"
    >
    <v-container>

       <!--- for large screen -->
        <div class="p-15 topbar d-none d-md-block">
          <div class="topbar-logo ">
            <div class="d-flex-wrapper">
              <div class="d-sm-logo float-right">
                <a @click="toHome()" style="cursor:auto">
                  <img v-if="settings && settings.length" :src="`${settings[0].logo}`" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <!--- // for large screen -->

        <!-- for small screen --->
        <div class="p-15 topbar d-block d-md-none ">
          <div class="topbar-logo mt-2">
            <div class="d-flex-wrapper">
              <div class="d-sm-logo text-center">
                <a @click="toHome()" style="cursor:auto">
                  <img v-if="settings && settings.length" :src="`${settings[0].logo}`" />
                </a>
              </div>
            </div>
          </div>
        </div>

      <!-- <div class="p-15 topbar">
        <div class="topbar-logo mt-2">
          <div class="d-flex-wrapper">
            <div class="d-sm-logo float-right">
              <a @click="toHome()" style="cursor:auto">
                <img v-if="settings && settings.length"
                  height="50"
                  :src="`${settings[0].logo}`"
                />
              </a>
            </div>
          </div>
        </div>
      </div> -->
    </v-container>
      <div class="project-details col-md-12" v-if="project_report">
        <div class="d-flex aling-items-center list-main-title mb-4 mt-10">
          <p class="list-main-title-content mb-0">South Asia - Main Results</p>
        </div>
        <div class="main-results d-flex justify-content-center m-auto mt-0">
          <p>Main Results of 2021</p>
        </div>
        <div class="row">
          <div class="col-md-10 offset-1">
            <div class="row result-outcome justify-content-center" v-if="project_report.main_results">
              <div class="col-12 col-md-4 col-6 px-0 pr-5" v-if="project_report.main_results.out_reach && project_report.main_results.out_reach > 0">
                <div class="outcome-content">
                  <p class="number">
                    <!-- {{ project_type.out_reach }} -->
                    <number v-if="project_report.main_results.out_reach" :number="project_report.main_results.out_reach"></number>
                    </p>
                  <p class="title">Reach</p>
                  <p class="ext-title">(Number of People and Enterprises)</p>
                </div>
              </div>
              <div class="col-12 col-md-4 col-6 px-0 pr-5" v-if="project_report.main_results.beneficiary && project_report.main_results.beneficiary > 0">
                <div class="outcome-content">
                    <p class="number">
                      <!-- {{ project_type.beneficiry }} -->
                      <number v-if="project_report.main_results.beneficiary" :number="project_report.main_results.beneficiary"></number>
                      </p>
                    <p class="title">Beneficiaries</p>
                    <p class="ext-title">(Number of People)</p>
                </div>
              </div>

              
              <div class="col-12 col-md-4 col-6 px-0 pr-5" v-if="project_report.main_results.beneficiary_enterprise && project_report.main_results.beneficiary_enterprise > 0">
                <div class="outcome-content">
                <p class="number">
                  <!-- {{ project_type.income_generated }} -->
                  <number v-if="project_report.main_results.beneficiary_enterprise" :number="project_report.main_results.beneficiary_enterprise"></number>
                  </p>
                  <p class="title">Beneficiaries</p>
                    <p class="ext-title">(Number of Enterprises)</p>
                </div>
              </div>
              <div class="col-12 col-md-4 col-6 px-0 pr-5" v-if="project_report.partners && project_report.partners > 0">
                <div class="outcome-content">
                    <p class="number">
                    <!-- {{ project_type.partner }} -->
                    <number v-if="project_report.partners" :number="project_report.partners"></number>
                    </p>
                    <p class="title">Partners</p>
                    <p class="ext-title">(Number of Public and Private Organisations)</p>
                </div>
              </div>
              <div class="col-12 col-md-4 col-6 px-0 pr-5" v-if="project_report.main_results.income_generated && project_report.main_results.income_generated > 0">
                <div class="outcome-content">
                  <p class="number">
                    <!-- {{ project_type.partner }} -->
                    <number v-if="project_report.main_results.income_generated" :number="project_report.main_results.income_generated"></number>
                    </p>
                    <p class="title">Income Generated</p>
                    <p class="ext-title">(In CHF)</p>
                  
                </div>
              </div>
              <div class="col-12 col-md-4 col-6 px-0 pr-5" v-if="project_report.main_results.private_sector_fund && project_report.main_results.private_sector_fund > 0">
                <div class="outcome-content">
                  <p class="number">
                    <!-- {{ project_type.partner }} -->
                    <number v-if="project_report.main_results.private_sector_fund" :number="project_report.main_results.private_sector_fund"></number>
                    </p>
                  <p class="title">Private Sector funding</p>
                  <p class="ext-title">(In CHF)</p>
                </div>
              </div>
            </div>
            <div class="projectlist-title d-flex justify-content-center m-auto">
              <p>Projects</p>
            </div>
            <div class="project-list-view row">
          <ul class="project-sub-menu" v-if="project_report.projects && project_report.projects.length">
            <li class="d-flex" v-for="(pro, cl) in project_report.projects" :key="cl">
              <img :src="pro.icon" width="20" height="20" alt="">
              <a href="javascript:void(0)" @click="toProject(pro.id)">{{
                pro.short_name
              }}</a>
            </li>
          </ul>
        </div>
        
          </div>
        </div>
        
        
      </div>
      <proleft @away="away" @toggleswiss="toggleswiss" :globe="globe" :first="first" :location="location" :left="left"></proleft>
      <div class="project-right list-bubbles">
        <Probubbles />
      </div> 
    </div>
    
  </div>
</template>

<script>
export default {
  data: () => ({
    show: null,
    globe: false,
    left: false,
    location: false,
    first: false,
    activeItem: null,
    baseEnvLocal: baseEnv,
    showCarousel: false,
    w_height: 0,
    componentKey: 0
  }),
  components: {
    proleft: () => import("../components/proleft"),
    Probubbles: () => import("../components/probubbles"),
    number: () => import("../components/number"),
  },
  props: {
    project_report: {},
    settings:{},
  },
  methods: {
    selectdetails(index) {
      this.show = index;
    },
    away:function(){
      this.globe = false
      this.first = false
      this.location = false
    },
    toHome() {
      this.$router.push("/").catch(()=>{});
    },
    toggleswiss: function(type)
    { 
      if(type == "region_menu"){
        this.globe = !this.globe
        this.location = false
        this.first = false
      }
      else if(type == "work_menu"){
        this.globe = false
        this.location = !this.location
        this.first = false
      }
      else{
        this.globe = false
        this.location = false
        this.first = !this.first
      }
      if(this.globe || this.first || this.location){
        this.left = true
      }
      else{
        this.left = false
      }
      
    },
    setactive: function (index) {
      if (this.activeItem == index) {
        this.activeItem = null;
      } else {
        this.activeItem = index;
      }
    },
    toProject(id) {
      this.$store
        .dispatch("project/getProject", id)
        .then(() => {
          this.$router.push({ name: "Project", params: { id: id } });
        })
        .catch(() => {});
    },
    getProjectlist(id, event) {
      let type = event.currentTarget.id;
      let obj = {
        id: id,
        type: type,
      };
      this.$store
        .dispatch("project/getProjectlist", obj)
        .then((response) => {
          this.$router.push({
            name: "ProjectList",
            params: { id: id, type: type },
          });
          (this.globe = false),
            (this.left = false),
            (this.location = false),
            (this.first = false),
            (this.activeItem = null);
        })
        .catch(() => {});
    },
    
  },
};
</script>

<style>
.swiss-sub-nav {
  min-width: 434px;
}
.working_area {
  min-width: 330px;
  padding-left: 0 !important;
}
.working_area .area-item {
  border: none;
}
.working_area .area-item a {
  list-style: none;
  display: inline-block;
  color: #000 !important;
  text-transform: none;
  font-size: 13px;
}
.project-icon .pro {
  /* width:14%!important; */
  border-color: #fff !important;
}
.project-details-main .bubble-big {
  left: 8% !important;
  bottom: 3% !important;
}
.project-details-main .bubble-top {
  left: 14% !important;
  bottom: 25% !important;
}
.project-details-main .another-bubble-big {
  right: 1% !important;
}
.project-details-main .another-bubble-top {
  right: 1% !important;
  top: 5% !important;
}
.project-details-main .bubble-bottom {
  left: 7% !important;
  bottom: 5% !important;
}
.top-circle {
  position: absolute;
  width: 100%;
  height: 1038.3px;
  left: 23.96px;
  top: -713.23px;
  background: #00477a;
  opacity: 0.1;
  border-radius: 50%;
}
.project-list-section {
  height: 100%;
  position: relative;
  width: 100%;
  overflow: hidden;
  background: #ffffff;
  padding: 10px;
}
.project-list-section .project-details-main {
  width: 100%;
  height: 100%;
  border-radius: 30px;
  position: relative;
  overflow: hidden;
}
.main-results {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 40px;
  /* box-sizing:content-box; */
  gap: 10px;
  margin-top:50px!important;
  margin-bottom:15px!important;
  /* position: absolute; */
  width: 50%;
  height: 55px;
  /* left: 25%; */
  /* top: 10%; */

  /* Secondary Text Color */

  background: #00477A;
  border-radius: 10px;
}
.projectlist-title{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 40px;
  /* box-sizing:content-box; */
  gap: 10px;
  margin-top:0px;
  margin-bottom:27px!important;
  /* position: absolute; */
  width: 25%;
  height: 55px;
  /* left: 25%; */
  /* top: 10%; */

  /* Secondary Text Color */

  background: #00477A;
  border-radius: 10px;
}
.main-results p,.projectlist-title p {
  font-family: "Calibri", sans-serif !important;
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 40px;
  text-align: justify;
  text-transform: uppercase;
  margin-bottom: 0;
  /* white */
  color: #ffffff;
}
.project-details-main .result-outcome {
  align-items: center;
  margin-bottom:5px;
  justify-content: center;
  width:100%;
}
.outcome-content {
  /* position: absolute; */
  width: 100%;
  height: 100%;
  /* left: 188.21px; */
  /* top: 248.04px; */
  padding: 0 0 10px 0;
  background: rgba(0, 71, 122, 0.1);
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin-right:10px;
}
.outcome-content:last-child{
  margin-right:0;
}
.outcome-content .title {
  font-family: "Calibri", sans-serif !important;
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 49px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 0;
  /* primary Header Color */

  color: #5A5F61;
}
.outcome-content .number {
  font-family: "Calibri", sans-serif !important;
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 64px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 0;
  /* primary Header Color */

  color: #00477a;
}
.project-list-view {
  /* position:absolute; */
  /* top:48%; */
  /* left:12%; */
  /* width: 80%; */
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 71, 122, 0.1);
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  min-height: 140px;
  margin-bottom:15px!important;
  width:100%;
}
.address-view {
  /* position:absolute; */
  /* top:73%; */
  /* left:12%; */
  /* width: 80%; */
  align-items: center;
  padding: 15px 10px 10px 10px;
  justify-content: center;
  background: rgba(0, 71, 122, 0.1);
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width:100%;
}
.address-view .address-content {
  padding: 0 10px;
  display: flex;
  gap: 10px;
  word-break: break-all;
}
.address-content p {
  font-family: "Calibri", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  /* identical to box height */

  text-align: justify;

  /* Secondary Text Color */

  color: #5A5F61;
}
.address-content span {
  /* primary Header Color */
  height: 22px;
  width: 22px;
  text-align: center;
  justify-content: center;
  border: 1px solid #00477a;
  border-radius: 50%;
}
.address-content span i {
  /* primary Header Color */
  color: #00477a;
}
.project-list-view .project-sub-menu li a {
  font-family: "Calibri", sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 29px;
  /* text-align: justify; */
  text-transform: uppercase;

  /* Secondary Text Color */

  color: #5A5F61;
}

.project-list-view .project-sub-menu {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    /* justify-content: center; */
    -moz-column-gap: 10px;
    column-gap: 10px;
    row-gap:8px;
    place-content: center;
    align-content: center;
    align-items:start;
    padding:20px;
    place-self: center;
}
.project-list-view .project-sub-menu li span i {
  color: #5a5f61;
}
.project-list-view .project-sub-menu li span {
  margin-top: 3%;
}
.project-list-view .project-sub-menu li{
  min-width:15%;
  gap:10px;
}
.list-main-title{
  padding:8px 24px;
  background: #E5EDF2;
  border-radius: 10px;
  justify-content:center;
  align-items:center;
  /* width:20%; */
  min-width:220px;
  width:max-content;
  gap:10px;
  margin: 0 auto;
}
.list-main-title .list-main-title-content{
  font-family: 'Calibri';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 29px;
text-align: justify;
text-transform: capitalize;

/* primary Header Color */

color: #00477A;
}
</style>
